<template lang="pug">
  .import-w
    el-upload(
              :action="action"
              class="cur-btn myuploader"
              name="file"
              ref="elUpload"
              :multiple="false"
              :auto-upload="true"
              :on-success="onImportSuccess"
              :on-error="onImportError"
            ) 导 入
</template>

<script>
export default {
  props:{
    action:{
      type:String,
      default:null
    }
  },
  methods: {
    onImportSuccess(e) {
      this.$emit("onImportSuccess",e)
      //客户让上传成功后下面的提示自动消失 所以这样
      setTimeout(() => {
        this.$refs.elUpload.clearFiles()
      }, 2000);
    },
    onImportError(e) {
      this.$emit("onImportSuccess",e)
    },
  },
};
</script>

<style lang="less" scoped>
.import-w {
  margin-left: 20px;
}
</style>
