<template lang="pug">
    .box-w(:class='className' :style='{width:w}')
      .ar.ar1
      .ar.ar2
      .ar.ar3
      .ar.ar4
      .c-box
        slot()
</template>

<script>
export default {
  props: {
    className: {
      type: String,
      default: "",
    },
    h: {
      type: [Number, String],
      default: "auto",
    },
    w: {
      type: [Number, String],
      default: "100%",
    },
    cornerSize: {
      type: String,
      default: "12px",
    },
  },
  data() {
    return {};
  },
  computed: {
    boxStyle() {
      return {
        height: this.h.is,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.box-w {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 1.5px;
  .ar {
    width: 10px;
    height: 10px;
    background: #3e9cd9;
    position: absolute;
    // z-index: -1;
  }
  .ar1 {
    top: 0;
    left: 0;
  }
  .ar2 {
    top: 0;
    right: 0;
  }
  .ar3 {
    bottom: 0;
    right: 0;
  }
  .ar4 {
    bottom: 0;
    left: 0;
  }
  .c-box {
    position: relative;
    z-index: 666;
    height: 100%;
    width: 100%;
    background: #101350;
    border: 1px solid #2a54a6;
  }
}
</style>
