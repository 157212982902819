<template lang="pug">
  .index-c-w
    .filter-box
      .filter-citem
        span.label-span1 市：
        Box3(w="152")
          el-select(class="my-select" v-model="city" :disabled="cityFlag" placeholder="请选择")
            el-option(v-for="item in cityList" :key="item.cityCode" :label="item.cityName" :value="item.cityCode")
      .filter-citem
        span.label-span2 区县：
        Box3(w="152")
          el-select(class="my-select" v-model="district" :disabled="districtFlag" placeholder="请选择")
            el-option(v-for="item in districtList" :key="item.districtCode" :label="item.districtName" :value="item.districtCode")
      .filter-citem
        span.label-span3 保单号：
        Box3(w="152")
          <el-input class="my-input" v-model="policyNo" placeholder="请输入"></el-input>
      .filter-citem
        span.label-span3 数据类型：
        Box3(w="152")
          el-select(class="my-select" v-model="dataType" placeholder="请选择")
            el-option(v-for="item in dataTypeList" :key="item.value" :label="item.label" :value="item.value")
      .filter-citem
        .cur-btn(@click="onSearch(1,years)") 查 询
      .filter-citem(v-if="userInfo.readyOnly == 1")
        ImportFile(:action="action" @onImportSuccess="onImportSuccess")
    .table-box
      MyTable1(
        :tableData="underwriteList"
        :columns="columns"
        tH="65vh"
        :total="pagation.total"
        :paginationShow="true"
        :currentPage="currentPage"
        @onCurrentPage="onCurrentPage"
      )
        <template v-slot:action="{row}">
          .cur-btn(@click="onDetail(row)" v-if="userInfo.readyOnly == 1") 编辑详情
          .cur-btn(@click="onDetail(row)" v-else) 详情
        </template>
</template>

<script>
import Box3 from "../../components/Box3.vue";
import MyTable1 from "../../components/MyTable1.vue";
import ImportFile from "../../components/ImportFile.vue";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      city: "", //市
      district: "", //区县
      cityFlag: false,
      districtFlag: false,
      dataType: "", //数据类型
      policyNo: "", //保单号
      tableData: [], //table 数据
      currentPage: 1,
      //数据类型
      dataTypeList: [
        { label: "全部", value: null },
        { label: "已续转", value: 1 },
        { label: "待续转", value: 0 },
      ],
      //表头
      columns: [
        { label: "市", prop: "cityName" },
        { label: "区县", prop: "districtName" },
        { label: "保单号", prop: "policyNo" },
        { label: "保障期限", prop: "guaranteePeriod", minWidth: 140 },
        { label: "承保人数", prop: "totalPeopleCounts" },
        { label: "保费(元)", prop: "premium" },
        { label: "数据类型", prop: "dataType" },
        { label: "保险方案", prop: "insurancePlanName" },
        { label: "操作", slot: "action" },
      ],
    };
  },
  components: {
    Box3,
    MyTable1,
    ImportFile,
  },
  watch: {
    city(newValue) {
      //监听城市选择，如果是 all 请求参数不用传
      if (newValue === "all") {
        this.getDistrictListAction();
        this.district = '';
      } else {
        this.getDistrictListAction({ cityCode: newValue });
      }
    },
    years(newVal) {
      this.currentPage = 1;
      this.onSearch(1,newVal); 
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.app.userInfo,
      years: (state) => state.app.years,
      pagation: (state) => state.underwritingCenter.pagation,
      underwriteList: (state) => state.underwritingCenter.underwriteList,
      cityList: (state) => state.app.cityList,
      districtList: (state) => state.app.districtList,
    }),
    action() {
      //附件上传url
      return process.env.NODE_ENV === "production"
        ? window.g.IMPORT_URL_UNDER_WRITE
        : "/api/underwrite/importUnderwriteData";
    },
  },
  created() {
    if (this.userInfo.cityCode !== 'C000') {
      this.city = this.userInfo.cityCode;
      this.cityFlag = true;
    }
    if (this.userInfo.districtCode !== 'D0000') {
      this.district = this.userInfo.districtCode;
      this.districtFlag = true;
    }
    this.getCityListAction();
    this.onSearch(1,this.years);
  },
  methods: {
    ...mapActions([
      "setPagationAction",
      "getUnderwriteListAction",
      "getDistrictListAction",
      "getCityListAction",
    ]),
    //数据导入成功回调
    onImportSuccess(e) {
      if (e.result === true) {
        this.onCurrentPage(1);
      } else {
        this.$message({
          type: "error",
          message: e.message,
        });
      }
    },
    //detail 按钮回调
    onDetail(row) {
      this.$router.push({
        path: "/underwriting_center/detail",
        query: { id: row.underwriteId },
      });
    },
    //分页改变
    onCurrentPage(currentPage) {
      /*let params = {
        policyNo: this.policyNo,
        dataType: this.dataType,
        cityCode: this.city === "all" ? "" : this.city,
        districtCode: this.district === "all" ? "" : this.district,
        ...this.pagation,
        currentPage: currentPage,
        pageSize: 10,
      };
      this.getUnderwriteListAction(params);*/
      this.currentPage = currentPage;
      this.onSearch(currentPage,this.years);
    },
    //搜索按钮回调
    onSearch(page,year) {
      let params = {
        currentPage: page ? page : 1,
        pageSize: 10,
        policyNo: this.policyNo,
        dataType: this.dataType,
        cityCode: this.city === "all" ? "" : this.city,
        districtCode: this.district === "all" ? "" : this.district
      };
      params.year = year;
      this.getUnderwriteListAction(params);
    },
  },
};
</script>

<style lang="less" scoped>
.index-c-w {
  .df;
  .dfc;
}
.table-box,
.filter-box {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
}
.filter-box {
  height: 72px;
  margin-bottom: 10px;
  .df;
  .dfac;
  .filter-citem {
    .df;
    .dfac;
    color: #305baa;
    height: 32px;
    & > span {
      text-align: right;
    }
    .cur-btn {
      margin-left: 50px;
    }
    .label-span1 {
      width: 60px;
    }
    .label-span2 {
      width: 80px;
    }
    .label-span3 {
      width: 100px;
    }
    /deep/ .ar {
      width: 10px;
      height: 10px;
    }
    /deep/ .box-w {
      height: 100%;
      width: 152px;
    }
  }
}
.table-box {
  padding: 20px;
  color: #fff;
  overflow: hidden;
  flex: 1;
}
/deep/ .el-icon-arrow-up:before {
  content: "\e6e1";
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}
/deep/ .popper__arrow,
/deep/ .el-select-dropdown__empty {
  display: none !important;
}
/deep/ .el-input__inner {
  background-color: #101350 !important;
}
</style>
